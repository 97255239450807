import React from 'react'

import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'gatsby-plugin-react-i18next'

interface SeoProps {
    title: string
    description?: string
    lang?: string
    meta?: []
    keywords?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    script?: any
}

const Seo = ({ title, description = '', lang = 'fr', meta = [], keywords, script = [] }: SeoProps) => (
    <StaticQuery
        query={detailsQuery}
        render={(data) => {
            const metaDescription = description || data.site.siteMetadata.description
            const metaKeyWords = keywords || data.site.siteMetadata.keywords

            return (
                <Helmet
                    htmlAttributes={{
                        lang,
                    }}
                    title={title}
                    titleTemplate={`%s | ${data.site.siteMetadata.title}`}
                    meta={[
                        {
                            name: 'description',
                            content: metaDescription,
                        },
                        {
                            property: 'og:title',
                            content: title,
                        },
                        {
                            property: 'og:description',
                            content: metaDescription,
                        },
                        {
                            property: 'og:type',
                            content: 'website',
                        },
                        {
                            name: 'twitter:card',
                            content: 'summary',
                        },
                        {
                            name: 'twitter:creator',
                            content: data.site.siteMetadata.author,
                        },
                        {
                            name: 'twitter:title',
                            content: title,
                        },
                        {
                            name: 'twitter:description',
                            content: metaDescription,
                        },
                        {
                            name: `keywords`,
                            content: metaKeyWords,
                        },
                    ].concat(meta)}
                    script={[].concat(script)}
                />
            )
        }}
    />
)

export default Seo

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                description
                author
                keywords
            }
        }
    }
`
