import * as React from 'react'

import { Box, Flex, Heading, Text, useMediaQuery } from '@chakra-ui/react'
import Seo from '@components/Seo'
import Section from '@components/core/Section'
import { graphql } from 'gatsby'
import { helmetJsonLdProp } from 'react-schemaorg'
import { Thing, WebSite } from 'schema-dts'

const jsonLd = helmetJsonLdProp<Thing>({
    '@context': 'https://schema.org',
    '@id': 'https://www.staffea.com',
    '@type': 'Organization',
    name: 'Staffea',
    url: 'https://www.staffea.com',
    address: {
        '@type': 'PostalAddress',
        addressLocality: 'Colombelles',
        postalCode: '14460',
        streetAddress: '8 rue Léopold Sédar-Senghor',
        addressCountry: {
            '@type': 'Country',
            name: 'FR',
        },
    },
    contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        availableLanguage: 'fr',
        email: 'contact@staffea.com',
    },
})

const jsonLdWebsite = helmetJsonLdProp<WebSite>({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://www.staffea.com',
    name: 'Staffea',
})

const PrivacyPolicy = () => {
    const [isTablet, isDesktop] = useMediaQuery(['(min-width: 1200px)', '(min-width: 1600px)'])

    return (
        <>
            <Seo
                title="Logiciel Gestion de Planning"
                description="Découvrez Staffea pour la gestion de vos plannings ➜ ✓ Installation rapide ✓ Création en 2 heures ✓ Gestion en temps réel ➜ Demandez une démo !"
                script={[jsonLd, jsonLdWebsite]}
            />

            <main>
                <Section paddingY={10} bg="indigo.100" id="staffea-section" name="staffea">
                    <Flex
                        pt={14}
                        pb={20}
                        gap={8}
                        align="center"
                        justify={['center', 'center', 'space-between']}
                        wrap="wrap"
                        maxW="1200px"
                        mx="auto"
                    >
                        <Flex
                            direction="column"
                            align="center"
                            mx="auto"
                            sx={{
                                '@media (min-width: 1440px)': {
                                    maxW: '80%',
                                    alignItems: 'center',
                                },
                            }}
                        >
                            <Heading
                                as="h1"
                                mb={10}
                                fontWeight="bold"
                                color="indigo.500"
                                textAlign={['center', 'center', 'start']}
                            >
                                Politique de confidentialité
                            </Heading>

                            <Box color="indigo.500">
                                <Flex gap={10} flexDirection={'column'}>
                                    <Text
                                        as="h2"
                                        fontWeight="bold"
                                        fontSize={isTablet && !isDesktop ? 'md' : '2xl'}
                                        mt={5}
                                    >
                                        1. Introduction
                                    </Text>

                                    <Text fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Bienvenue sur le site web de Staffea accessible à l’adresse : &quot;
                                        <Text
                                            as="a"
                                            fontSize={'lg'}
                                            href="https://www.staffea.com"
                                            target="blank"
                                            _hover={{ color: 'green.500' }}
                                            transition="all 0.2s"
                                            fontWeight={'bold'}
                                            textDecoration={'underline'}
                                            m={1}
                                        >
                                            www.staffea.com
                                        </Text>
                                        &quot;. Chez Staffea, nous nous engageons à respecter et à protéger votre vie
                                        privée. La présente Politique de confidentialité décrit comment nous collectons,
                                        utilisons et partageons vos informations personnelles lorsque vous utilisez
                                        notre site web.
                                    </Text>

                                    <Text
                                        as="h2"
                                        fontWeight="bold"
                                        fontSize={isTablet && !isDesktop ? 'md' : '2xl'}
                                        mt={5}
                                    >
                                        2. Collectes des informations
                                    </Text>

                                    <Text
                                        as="h3"
                                        fontWeight="bold"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'xl'}
                                        mt={-8}
                                    >
                                        2.1. Informations que vous nous fournissez
                                    </Text>

                                    <Text fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Lorsque vous utilisez notre site web, vous pouvez choisir de nous fournir
                                        certaines informations personnelles, telles que votre nom, votre adresse e-mail,
                                        et d’autres informations de contact lorsque vous remplissez des formulaires sur
                                        le site.
                                    </Text>

                                    <Text as="h3" fontWeight="bold" fontSize={isTablet && !isDesktop ? 'sm' : 'xl'}>
                                        2.2. Informations que nous collectons automatiquement
                                    </Text>

                                    <Text fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Nous pouvons également collecter des informations automatiquement à partir de
                                        votre navigateur lorsque vous visitez notre site web. Cela peut inclure des
                                        informations telles que votre adresse IP, le type de navigateur que vous
                                        utilisez, les pages que vous consultez sur notre site, la date et l’heure de
                                        votre visite, et d’autres données de diagnostic.
                                    </Text>

                                    <Text
                                        as="h2"
                                        fontWeight="bold"
                                        fontSize={isTablet && !isDesktop ? 'md' : '2xl'}
                                        mt={5}
                                    >
                                        3. Utilisation des informations
                                    </Text>

                                    <Text fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Nous utilisons les informations collectées pour diverses finalités, notamment
                                        pour fournir, exploiter et améliorer notre site web, pour vous contacter et
                                        traiter votre demande, pour personnaliser votre expérience sur le site, et pour
                                        analyser comment vous utilisez notre site.
                                    </Text>

                                    <Text
                                        as="h2"
                                        fontWeight="bold"
                                        fontSize={isTablet && !isDesktop ? 'md' : '2xl'}
                                        mt={5}
                                    >
                                        4. Partage des informations
                                    </Text>

                                    <Text fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Nous ne partageons pas vos informations personnelles avec des tiers, sauf si
                                        expressément indiqué.
                                    </Text>

                                    <Text
                                        as="h2"
                                        fontWeight="bold"
                                        fontSize={isTablet && !isDesktop ? 'md' : '2xl'}
                                        mt={5}
                                    >
                                        5. Sécurité des informations
                                    </Text>

                                    <Text fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Nous prenons des mesures de sécurité appropriées pour protéger vos informations
                                        personnelles contre toute perte, accès non autorisé, divulgation, altération et
                                        destruction.
                                    </Text>

                                    <Text
                                        as="h2"
                                        fontWeight="bold"
                                        fontSize={isTablet && !isDesktop ? 'md' : '2xl'}
                                        mt={5}
                                    >
                                        6. Vos droits
                                    </Text>

                                    <Text fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Vous avez le droit de demander l’accès, la rectification, la suppression ou la
                                        limitation de l’utilisation de vos informations personnelles. Vous pouvez
                                        exercer ces droits en nous contactant à l’adresse : &quot;
                                        <Text
                                            as="a"
                                            fontSize={isTablet && !isDesktop ? 'xs' : 'lg'}
                                            fontWeight={'bold'}
                                            href="mailto:contact@staffea.com"
                                            target="blank"
                                            _hover={{ color: 'green.500' }}
                                            transition="all 0.2s"
                                            textDecoration={'underline'}
                                        >
                                            contact@staffea.com
                                        </Text>
                                        &quot;
                                    </Text>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>
                </Section>
            </main>
        </>
    )
}

export default PrivacyPolicy

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["privacyPolicy"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
